<!-- 打款记录 -->
<template>
    <div class="w100">
        <header>
        <!-- <el-form :inline="true" :model="filterObj" class="demo-form-inline">
                <el-form-item>
                    <el-select v-model="reqCardTypeObj.gc_type" placeholder="卡类">
                        <el-option :label="item.text" v-for="item in cardSpeciesList" :key="item.value"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                            </el-form> -->
        </header>
        <el-table border :data="list" style="width: 90%" v-loading="loading">
            <el-table-column prop="pr_no" label="订单号">
            </el-table-column>
            <el-table-column prop="pr_price" label="打款金额">
            </el-table-column>
            <el-table-column prop="u_ali_account" label="收款账号">
            </el-table-column>
            <el-table-column prop="u_ali_realname" label="收款人姓名">
            </el-table-column>

            <el-table-column prop="u_ali_realname" label="打款时间">
                <template slot-scope="scope">
                    {{ scope.row.pr_updatetime | ts2Date() }}
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    {{
                        scope.row.pay_status == 0
                        ? "打款中"
                        : scope.row.pay_status == 1
                            ? "已打款"
                            : scope.row.pay_status == 2
                                ? "打款失败"
                                : "正在重新打款"
                    }}
                </template>
            </el-table-column>
            <el-table-column prop="co_status" label="备注">
            </el-table-column>
            <el-table-column prop="u_ali_realname" label="添加时间">
                <template slot-scope="scope">
                    {{ scope.row.pr_addtime | ts2Date() }}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pager.page"
            :page-size="pager.limit" layout="total, prev, pager, next,jumper" :total="pager.total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            list: [],
            filterObj: {

            },
            pager: {
                page: 1,
                limit: 7,
                total: 0
            }
        };
    },

    components: {},
    filters: {
        ts2Date: (timestamp) => {
            if (timestamp == 0) {
                return "";
            }
            let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                Y = date.getFullYear() + "-",
                M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-",
                D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
                h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                    ":",
                m =
                    (date.getMinutes() < 10
                        ? "0" + date.getMinutes()
                        : date.getMinutes()) + ":",
                s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
    },
    mounted() {
        this.getList()
    },

    methods: {
        handleSizeChange(val) {
            this.pager.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            this.pager.page = val
            this.getList()

        },
        async getList() {
            let res = await this.$axios
                .get("/api/account/getPaymentRecordList", {
                    params: { ...this.pager },
                })

            this.list = res.data.rows
            this.pager.total = res.data.total
        }
    }
}

</script>
<style scoped></style>